<template>
  <div ref="pageBox">
    <SearchBox ref="SearchBox">
      <el-form slot="form" :inline="true" label-width="80px" class="unitWidth" size="small">
        <el-form-item label="岗位名称">
          <el-input v-model="query.postName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="招聘公司名称">
          <el-input v-model="query.companyName" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <template slot="btns">
        <el-button size="small" @click="selrele()" type="primary">搜索</el-button>
      </template>
    </SearchBox>
    <LabelContent title="招聘内容审核" ref="LabelContent"> </LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        class="center"
        :height="tableHeight + 'px'"
        :default-sort="{ prop: 'value', order: 'salary' }"
      >
        <el-table-column prop="postName" label="发布职位" :sort-method="sortByDate" />
        <el-table-column prop="workExperience" label="工作经验" />
        <el-table-column prop="workCity" label="工作地" show-overflow-tooltip />
        <el-table-column prop="salary" label="月薪" :formatter="formattersalary" />
        <el-table-column prop="companyName" label="招聘公司" show-overflow-tooltip />
        <el-table-column prop="postInfo" label="职位信息" show-overflow-tooltip />
        <el-table-column prop="companyInfo" label="公司信息" show-overflow-tooltip />
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" @click="tableDetail(scope.row)">审核</el-button>
            <el-button type="primary">置顶</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="paginationsizeChange"
        @current-change="paginationcurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      tableHeight: 0, //表格高度
      tableData: [],
      total: 0,
      query: {
        auditStatus: "",
        companyName: "",
        pageNum: 1,
        pageSize: 10,
        postName: "",
        timeEnd: "",
        timeStart: "",
      },
    }
  },
  mounted () {
    this.loading = true
    //设置表格高度
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelContent
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.init()
  },
  methods: {
    //查询 
    selrele () {
      this.init()
    },
    //招聘合作列表信息
    async init () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/recruited/page",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data.list
        that.total = data.total
        that.loading = false
      }
    },
    //删除 
    deleteApi (data) {
      const _this = this
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => { })
    },
    //分页

    //每页条数被改变
    paginationsizeChange (val) {
      this.query.pageSize = val
    },
    //当前页数被改变
    paginationcurrentChange (val) {
      this.query.pageNum = val
    },
    //月薪资 过滤器
    formattersalary (row, column) {
      return row.salary + "K"
    },
    //从后台获取数据,重新排序
    sortChange (val) {
      // 详细文档参考： https://element.eleme.cn/2.13/#/zh-CN/component/table
      console.log(val)
    },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      console.log(a, b)
      if (a < b) return -1
    },
    //跳转审核
    tableDetail (row) {
      this.$router.push({
        path: "/recrui_detail/" + row.recruitId
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  width: 4.5vw;
  height: 2vw;
  line-height: 0px;
}
.stywid {
  margin-left: 1vw;
  margin-top: 2.5vw;
  width: 17vw;
}
.stywid1 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 2.5vw;
}
</style>
